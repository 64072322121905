<template>
	<teleport to="head">
		<component :is="'script'" type="application/ld+json">
			{{ generateSearchEngineEventData(singleEvent) }}
		</component>

		<meta property="og:title" :content="singleEvent.title" />
		<meta property="og:type" content="website" />
		<meta property="og:url" :content="currentUrl" />
		<meta property="og:image" :content="image" />
		<meta
			property="og:description"
			:content="singleEvent.short_event_description"
		/>
		<meta name="twitter:card" content="summary_large_image" />
		<meta name="twitter:title" :content="singleEvent.title" />
		<meta name="twitter:url" :content="currentUrl" />
		<meta name="twitter:image" :content="image" />
		<meta
			name="twitter:description"
			:content="singleEvent.short_event_description"
		/>
	</teleport>
	<div>
		<div class="w-full flex flex-col pb-10 bg-noise-pale">
			<div class="">
				<div
					class="mb-4 md:mb-0 w-full mx-auto relative"
					:class="isMultiLocationsEvent ? 'h-96' : 'h-72'"
				>
					<div
						class="absolute w-full h-full z-10 bg-gradient-to-b from-transparent to-black/70"
					></div>
					<img
						loading="lazy"
						:src="image"
						class="absolute top-0 w-full h-full z-0 object-cover"
						alt="Event main hero image"
						:title="singleEvent.title"
					/>
					<div
						class="p-4 absolute bottom-1/2 z-20 text-center w-full"
						:class="isMultiLocationsEvent ? 'mb-8' : ''"
					>
						<h2
							v-if="singleEvent.title"
							class="text-4xl font-semibold text-gray-100 leading-tight ml-5"
						>
							<span v-html="singleEvent.title"></span>
						</h2>
					</div>
					<div class="p-4 absolute bottom-10 z-20 text-center w-full">
						<!-- Here is the location dropdown -->
						<restaurant-location-selector
							v-if="isMultiLocationsEvent"
							colour="dark"
							parent-classes="events-locations-dropdown"
							:isEventsPageLocationDropdown="true"
							:restaurants="getEventLocations()"
							@location-selected="
								setSelectedLocationId($event.id)
							"
						>
						</restaurant-location-selector>

						<a
							:href="generateCtaUrl()"
							class="standard-button bg-mustard text-brand border border-mustard mt-0 text-2xl"
							:class="
								isMultiLocationsEvent && !selectedLocation
									? 'opacity-50 cursor-not-allowed pointer-events-none'
									: ''
							"
							v-if="
								singleEvent.cta_name && singleEvent.cta_btn_url
							"
						>
							<ticket-icon
								v-if="!isFree"
								class="inline-flex w-6"
							></ticket-icon>
							{{ singleEvent.cta_name }}
						</a>
					</div>
				</div>

				<div class="bg-white md:mx-28 p-4 sm:p-16 lg:px-16 xl:px-32">
					<div class="lg:grid lg:grid-cols-4 flex flex-col">
						<div
							class="col-span-1 self-center hidden lg:flex cursor-pointer"
							@click="$router.push('/events')"
							@keypress="$router.push('/events')"
						>
							<chevron-left-icon
								class="text-brand w-6 lg:w-8"
							></chevron-left-icon>
							<h5 class="mt-0 self-center">Back</h5>
						</div>

						<div class="lg:col-span-2 flex flex-col self-center">
							<p
								v-if="singleEvent.all_day"
								class="mt-0 font-bold text-xl"
							>
								{{
									this.getDateOnlyFormat(
										singleEvent.start_date
									)
								}}
								<span
									v-if="
										!isSameDate(
											singleEvent.start_date,
											singleEvent.end_date
										)
									"
								>
									-
									{{
										this.getDateOnlyFormat(
											singleEvent.end_date
										)
									}}
								</span>
								<br />
								All Day
							</p>

							<p v-else class="mt-0 font-bold text-xl">
								{{ computedEventTime }}
							</p>

							<p v-if="!isSingleEvent" class="font-bold text-xl">
								<span v-html="locationName"></span>
							</p>
							<p v-else class="font-bold text-xl">
								@
								<a
									:href="venueMapLink"
									target="_blank"
									rel="noopener noreferrer"
									class="underline"
								>
									<span v-html="locationName"></span>
								</a>
							</p>
						</div>

						<div
							v-if="shouldShowPrice"
							class="lg:col-span-1 mt-16 lg:mt-0 flex self-center justify-end"
						>
							<p class="mt-0 font-bold text-xl">
								Price: {{ singleEvent.cost }}
							</p>
						</div>
					</div>

					<div
						v-html="singleEvent.description"
						class="my-16 text-left lg:min-h-[20rem]"
					></div>

					<div class="flex justify-between">
						<div
							class="flex self-start items-center cursor-pointer"
						>
							<div class="relative inline-block">
								<button
									v-if="
										singleEvent.show_add_to_calendar_btn &&
										selectedLocation
									"
									@click="showDropdown"
									@keypress="showDropdown"
									type="button"
									class="standard-button border border-gray-400 text-gray-600 bg-cream hover:text-gray-800 px-4 mt-0"
								>
									<calendar-icon
										class="inline-flex w-6 pb-1"
									></calendar-icon>
									Add to Calendar
								</button>

								<div
									v-if="show"
									class="absolute z-10 mt-2 py-2 w-full bg-cream shadow-lg font-parisine"
								>
									<a
										:href="generateCalendarLink('google')"
										class="block px-4 py-1 text-brand hover:bg-cream hover:text-white text-left"
										target="_blank"
										rel="noopener noreferrer"
										title="Add to Google Calendar"
										>Google Calendar</a
									>

									<a
										:href="generateCalendarLink('outlook')"
										class="block px-4 py-1 text-brand hover:bg-cream hover:text-white text-left"
										target="_blank"
										rel="noopener noreferrer"
										title="Add to Outlook Calendar"
										>Outlook 365</a
									>
								</div>
							</div>
						</div>

						<div class="flex justify-end items-center">
							<p class="mt-0 mr-3">Share on:</p>
							<div class="inline-flex">
								<a
									:href="facebookShareLink"
									target="_blank"
									rel="noopener"
									class="wid"
									title="Share on Facebook"
									><img
										loading="lazy"
										alt="Image"
										src="@/assets/facebook.png"
								/></a>

								<a
									:href="twitterShareLink"
									target="_blank"
									rel="noopener"
									class=""
									title="Share on Twitter"
									><img
										loading="lazy"
										alt="Image"
										class="ml-5"
										src="@/assets/twitter.png"
								/></a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import RestaurantLocationSelector from '@/components/RestaurantLocationSelector.vue';
import defaultImage from '@/assets/default-event-picture.jpg';
import {
	ChevronLeftIcon,
	TicketIcon,
	CalendarIcon,
} from '@heroicons/vue/outline';
import { mapState } from 'vuex';
import { DateTime } from 'luxon';

export default {
	name: 'SingleEventView',
	components: {
		ChevronLeftIcon,
		TicketIcon,
		CalendarIcon,
		RestaurantLocationSelector,
	},
	emits: ['contentLoaded', 'displayPlaceholder'],
	computed: {
		...mapState(['singleEvent']),
		currentUrl() {
			return window.location.href;
		},
		image() {
			return this.singleEvent.image
				? this.singleEvent.image.url
				: defaultImage;
		},
		shouldShowPrice() {
			return this.singleEvent.cost;
		},
		isFree() {
			return (
				!this.singleEvent.cost ||
				this.singleEvent.cost === this.freeText
			);
		},
		venueMapLink() {
			return this.getGoogleMapsUrl(
				`cote ${this.singleEvent.venue_map?.address}`
			);
		},
		facebookShareLink() {
			return this.getFacebookShareUrl(this.currentUrl);
		},
		twitterShareLink() {
			return this.getTwitterShareUrl(this.currentUrl);
		},
		computedEventTime() {
			if (this.singleEvent.show_event_time === 'yes') {
				if (this.singleEvent.show_end_time === 'yes') {
					return this.getDateTimeRange(
						this.singleEvent.start_date,
						this.singleEvent.end_date
					);
				}

				return this.getDateWithTimeFormat(this.singleEvent.start_date);
			}

			return this.getDateOnlyFormat(this.singleEvent.start_date);
		},
		locationName() {
			switch (this.singleEvent.event_type) {
				case this.allLocationsEventString:
					return this.allLocationsEventString;
				case this.singleEventString:
					const locationId = this.singleEvent.event_locations[0];
					return this.$store.getters.getRestaurantById(locationId)
						.post_title;
				case this.variousEventString:
					return 'Various Locations';
			}
		},
		isSingleEvent() {
			return this.singleEvent.event_type === this.singleEventString;
		},
		isVariousLocationEvent() {
			return this.singleEvent.event_type === this.variousEventString;
		},
		isNationalEvent() {
			return this.singleEvent.event_type === this.allLocationsEventString;
		},
		isMultiLocationsEvent() {
			return this.isNationalEvent || this.isVariousLocationEvent;
		},
	},
	data() {
		return {
			allLocationsEventString: 'All Locations',
			variousEventString: 'Various',
			singleEventString: 'Single',
			calendarTypeGoogle: 'google',
			calendarTypeOutlook: 'outlook',
			dateTimeFormatBasic: 'basic',
			dateTimeFormatExtended: 'extended',
			dateTimeFormatExtendedWithTz: 'extended_with_tz',
			dateTimeFormatDateOnly: 'date_only',
			domain: process.env.VUE_APP_DOMAIN,
			schema_base_url: 'https://schema.org',
			show: false,
			selectedLocation: null,
		};
	},
	methods: {
		generateCtaUrl() {
			let url = this.singleEvent.cta_btn_url;
			// If the button includes book-a-table, then dynamically create a cta url to lead user to booking widget,
			// prepopulating the details from the event (date & location)
			if (this.singleEvent.cta_btn_url.includes('book-a-table')) {
				const eventDate =
					this.singleEvent.start_date_details.year +
					'-' +
					this.singleEvent.start_date_details.month +
					'-' +
					this.singleEvent.start_date_details.day;
				url =
					this.singleEvent.cta_btn_url +
					`?date=${eventDate}&brasserieId=${this.selectedLocation}`;
			}
			return url;
		},
		setSelectedLocationId(id) {
			this.selectedLocation = id;
		},
		generateCalendarLink(calendarType) {
			const { start_date, end_date, timezone, title } = this.singleEvent;
			const locationData = this.$store.getters.getRestaurantById(
				this.selectedLocation
			);

			if (calendarType === this.calendarTypeGoogle) {
				const formattedStartDate = this.formatEventDate(
					start_date,
					timezone,
					this.dateTimeFormatBasic
				);
				const formattedEndDate = this.formatEventDate(
					end_date,
					timezone,
					this.dateTimeFormatBasic
				);
				return `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
					title
				)}@Côte-${encodeURIComponent(
					locationData.post_title
				)}&dates=${encodeURIComponent(
					formattedStartDate
				)}/${encodeURIComponent(
					formattedEndDate
				)}&ctz=${encodeURIComponent(
					timezone
				)}&location=${encodeURIComponent(locationData.address_1)}`;
			}

			if (calendarType === this.calendarTypeOutlook) {
				const formattedStartDate = this.formatEventDate(
					start_date,
					timezone,
					this.dateTimeFormatExtended
				);
				const formattedEndDate = this.formatEventDate(
					end_date,
					timezone,
					this.dateTimeFormatExtended
				);
				return `https://outlook.office365.com/owa?path=/calendar/action/compose&rrv=addevent&startdt=${encodeURIComponent(
					formattedStartDate
				)}&enddt=${encodeURIComponent(
					formattedEndDate
				)}&subject=${encodeURIComponent(
					title
				)}@Côte-${encodeURIComponent(
					locationData.post_title
				)}&location=${encodeURIComponent(locationData.address_1)}`;
			}
		},
		getEventLocations() {
			let locations = [];
			if (this.isNationalEvent) {
				locations = this.$store.state.restaurants;
			} else {
				locations = this.singleEvent.event_locations.map(
					(locationId) => {
						return this.$store.getters.getRestaurantById(
							locationId
						);
					}
				);
			}
			return locations;
		},
		// This function generates the schema.org data for the event, which is used by search engines to display the event in search results.
		generateSearchEngineEventData(eventData) {
			if (!this.singleEvent || !this.singleEvent.id) {
				return;
			}
			const result = [];
			const {
				timezone,
				start_date: eventStartDate,
				end_date: eventEndDate,
				all_day: isAllDayEvent,
				id: eventId,
				title,
				short_event_description: eventDescription,
			} = eventData;

			const eventUrl = this.domain + '/events/' + eventId;

			const format = isAllDayEvent
				? this.dateTimeFormatDateOnly
				: this.dateTimeFormatExtendedWithTz;
			const formattedStartDate = this.formatEventDate(
				eventStartDate,
				timezone,
				format
			);
			const formattedEndDate = this.formatEventDate(
				eventEndDate,
				timezone,
				format
			);
			const locations = this.isNationalEvent
				? this.$store.state.restaurants
				: this.singleEvent.event_locations;

			locations.forEach((location) => {
				// When we have a national event, then the location data already exists in the store, so we can just use that.
				// otherwise we need to loop the id's from the backend for the given event and get the location data from the store.
				const locationData = this.isNationalEvent
					? location
					: this.$store.getters.getRestaurantById(location);
				const schema = {
					'@context': this.schema_base_url,
					'@type': 'Event',
					name: title,
					description: eventDescription,
					url: eventUrl,
					eventAttendanceMode:
						this.schema_base_url + '/OfflineEventAttendanceMode',
					eventStatus: this.schema_base_url + '/EventScheduled',
					startDate: formattedStartDate,
					endDate: formattedEndDate,
					location: {
						'@type': 'Place',
						name: locationData.post_title,
						address: {
							'@type': 'PostalAddress',
							streetAddress: locationData.address_1,
							addressLocality: locationData.town_city,
							postalCode: locationData.postcode,
							addressCountry: 'United Kingdom',
						},
						telephone: locationData.phone_number,
						image: locationData.restaurant_preview,
					},
					image: [eventData.image?.url],
					organizer: {
						'@type': 'Organization',
						name: 'Côte Brasserie',
						url: this.domain,
					},
					offers: {
						'@type': 'Offer',
						price: eventData.cost_details?.values,
						priceCurrency: eventData.cost_details?.currency_code,
						url: eventUrl,
						availability: this.schema_base_url + '/InStock',
					},
					performer: 'Organization',
				};
				result.push(schema);
			});

			return result;
		},
		formatEventDate(date, timezone, format) {
			const formats = {
				basic: "yyyyMMdd'T'HHmmss",
				extended: "yyyy-MM-dd'T'HH:mm:ss",
				extended_with_tz: "yyyy-MM-dd'T'HH:mm:ssZZ",
				date_only: 'yyyy-MM-dd',
			};

			return DateTime.fromFormat(date, 'yyyy-MM-dd HH:mm:ss', {
				zone: timezone,
			}).toFormat(formats[format]);
		},

		async getEvent() {
			const { id } = this.$route.params;
			if (
				!this.$store.getters.checkIfPageContentLoaded(
					this.$route.meta.id
				)
			) {
				if (this.$store.getters.isFirstSplashScreenHidden) {
					this.$emit('displayPlaceholder');
				}
				await this.$store.dispatch('getEventById', id);
				this.$emit('contentLoaded');
			}
		},
		showDropdown(event) {
			this.show = !this.show;
			event.stopPropagation();
		},
		hideDropdown() {
			this.show = false;
		},
	},
	async created() {
		await this.getEvent();
		// in case its a single event, we always grab the eventId from first element of the event_locations and set it.
		if (this.singleEvent.event_type === this.singleEventString) {
			this.setSelectedLocationId(this.singleEvent.event_locations[0]);
		}
	},
	mounted() {
		window.addEventListener('click', this.hideDropdown);
	},
	beforeDestroy() {
		window.removeEventListener('click', this.hideDropdown);
	},
};
</script>
